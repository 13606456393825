import React from 'react';
import MultiSelect from 'multiselect-react-dropdown';

type MultiSelectItems = {
  key: any;
  displayName: string;
};

type MultiSelectProps = {
  options: MultiSelectItems[];
  selectedValues: any[];
  onSelect: any;
  placeholder: string;
}

export const MultiSelectDropDown: React.FC<MultiSelectProps> = ({
  options,
  selectedValues,
  onSelect,
  placeholder
}) => {
  return (
    <MultiSelect
      displayValue="displayName"
      selectedValues={selectedValues}
      onSelect={(selectedList) =>  onSelect(selectedList)}
      onRemove={(selectedList) => onSelect(selectedList)}
      options={options}
      placeholder={placeholder}
      style={{
        chips: {
          background: '#334bc1',
        },
        multiselectContainer: {
          'background-color': 'black',
        },
        searchBox: {
          border: '1px solid white',
          'border-radius': '10px',
        },
        optionContainer: {
          background: 'black',
          color: 'white'
        }
      }}
    />
  )
}

import React, { useState, useEffect } from 'react';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonList,
  IonSpinner,
  IonAlert,
  IonButtons,
  IonMenuButton,
  useIonToast,
  IonGrid,
  IonRow,
  IonIcon,
} from '@ionic/react';
import { useGetFinancialInstitutionsAll } from '../../../features/financial-institutions/financial-institutions-resolver';
import {
  useGetDescriptors,
  usePostDescriptorsReview
} from '../../../features/descriptors/descriptors-resolver';
import {
  Centered,
  BorderedIonCol,
  SubmitButtonContainer,
  SubmitButton,
  FinancialInstitutionsContainer,
  FinancialInstitutionsButtonsContainer,
  FilterButton,
  CleanButton,
} from './styles';
import DescriptorItem from './components/DescriptorItem';
import { descriptorReviewDataLimit } from '../../../shared/constants';
import { DescriptorReviewTypeMap } from '../../../features/descriptors/descriptors-types';
import { informationCircle } from 'ionicons/icons';
import { MultiSelectDropDown } from '../../../components/MultiSelectDropDown';
import { useMediaQuery } from 'usehooks-ts';

const getParams = {
  limit: descriptorReviewDataLimit,
  fiid: [],
};

const DescriptorReview: React.FC = () => {
  const [presentToast, dismissToast] = useIonToast();
  const [selectedFIIDs, setSelectedFIIDs] = useState<{ key: string; displayName: string; }[]>([]);
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const [
    {
      data: financialInstitutions,
      isLoading: areFinancialInstitutionsLoading,
    },
    getFinancialInstitutions
  ] = useGetFinancialInstitutionsAll();

  const [
    {
      data: descriptors,
      isLoading: areDescriptorsLoading,
      error: descriptorsError
    },
    getDescriptors
  ] = useGetDescriptors();

  const [
    { isLoading: isPostReviewLoading },
    postReview,
  ] = usePostDescriptorsReview();

  const [descriptorReviews, setDescriptorReviews] = useState<
    Record<number, keyof typeof DescriptorReviewTypeMap>
  >({});

  const [openSubmitHelpPopover, setOpenSubmitHelpPopover] = useState(false);

  const handleAction = (id: number, action: keyof typeof DescriptorReviewTypeMap) => {
    setDescriptorReviews(prev => ({ ...prev, [id]: action }));
  };

  const allActionsSelected = descriptors && descriptors.every(d => descriptorReviews[d.id]);

  const handleSubmit = async () => {
    if (allActionsSelected) {
      try {
        const payload = {
          descriptors: Object
            .entries(descriptorReviews)
            .map(([id, reviewResult]) => ({
              id: parseInt(id),
              reviewType: DescriptorReviewTypeMap[reviewResult]
            }))
        };
        const result = await postReview(payload);

        if (result.errorCode) {
          if (result.statusCode && result.statusCode !== 500) {
            throw new Error('Review period expired, please refresh the page to get new descriptors.');
          } else {
            throw new Error('Could not submit review. Try again later.');
          }
        }

        presentToast({
          message: 'Review submitted successfully',
          duration: 1500,
          position: 'top',
          color: 'success'
        });

        const fiids = selectedFIIDs?.map(({ key }) => key) ?? [];
        getDescriptors({
          refresh: true,
          params: { ...getParams, fiids },
        });
      } catch (error: any) {
      presentToast({
        message: error.message,
        position: 'top',
        color: 'danger',
        buttons: [{
          side: 'end',
          text: 'OK',
          handler: () => {
            dismissToast();
          },
        }]
      });
    }
  }
};

useEffect(() => {
  getDescriptors({
    refresh: true,
    params: { ...getParams },
  });
  getFinancialInstitutions()
}, []);

useEffect(() => {
  setDescriptorReviews({});
}, [descriptors]);

const cleanUp = () => {
  setSelectedFIIDs([])

  // Is the get Descriptors because it's cleaning everything.
  getDescriptors({
    refresh: true,
    params: { ...getParams, fiid: [''] },
  });
}

const handleApplyFilter = () => {
  const fiids = selectedFIIDs?.map(({ key }) => key) ?? [];

  getDescriptors({
    refresh: true,
    params: { ...getParams, fiids },
  });
}

const financialInstitutionsToSelect = () => {
  if (!areFinancialInstitutionsLoading && financialInstitutions) {
    return financialInstitutions.map(fi => ({ key: fi.fiid, displayName: `${fi.fiid} - ${fi.name}` }))
  }

  return []
}

return (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton data-testid="menu-button"></IonMenuButton>
        </IonButtons>
        <IonTitle>Descriptor Review</IonTitle>
      </IonToolbar>
    </IonHeader>

    {(areDescriptorsLoading || areFinancialInstitutionsLoading) && (
      <Centered>
        <IonSpinner data-testid="page-spinner" title="page-spinner" name="crescent" />
      </Centered>
    )}

    {
      !areFinancialInstitutionsLoading && financialInstitutionsToSelect().length && (
        <FinancialInstitutionsContainer>
          <MultiSelectDropDown
            options={financialInstitutionsToSelect()}
            selectedValues={selectedFIIDs}
            onSelect={setSelectedFIIDs}
            placeholder='Search by FI'
          />
          <FinancialInstitutionsButtonsContainer>
            {
              !!selectedFIIDs.length &&
              (
                <CleanButton
                  color="danger"
                  onClick={cleanUp}
                >
                  Clean All Filters
                </CleanButton>
              )
            }
            <FilterButton onClick={handleApplyFilter}>
              Apply Filter
            </FilterButton>
          </FinancialInstitutionsButtonsContainer>
        </FinancialInstitutionsContainer>
      )
    }

    {!areDescriptorsLoading && (
      <IonContent>
        {(descriptors && descriptors.length) ? (
          <>
            {isDesktop ? (
              <IonGrid>
                <IonRow>
                  <BorderedIonCol size="1.5">
                    <strong>Parsed Descriptor</strong>
                  </BorderedIonCol>
                  <BorderedIonCol size="2">
                    <strong>MC Descriptor</strong>
                  </BorderedIonCol>
                  <BorderedIonCol size="0.75">
                    <strong>MC Confidence Score</strong>
                  </BorderedIonCol>
                  <BorderedIonCol size="2">
                    <strong>Suggested Merchant Name</strong>
                  </BorderedIonCol>
                  <BorderedIonCol size="2">
                    <strong>Suggested Address</strong>
                  </BorderedIonCol>
                  <BorderedIonCol size="1">
                    <strong>Suggested City</strong>
                  </BorderedIonCol>
                  <BorderedIonCol size="0.75">
                    <strong>Suggested State</strong>
                  </BorderedIonCol>
                  <BorderedIonCol size="2">
                    <strong>Actions</strong>
                  </BorderedIonCol>
                </IonRow>
                {descriptors.map(descriptor => (
                  <DescriptorItem
                    key={descriptor.id}
                    descriptor={descriptor}
                    onAction={handleAction}
                    selectedAction={descriptorReviews[descriptor.id] || null}
                    isLoading={isPostReviewLoading || areDescriptorsLoading}
                    isDesktop={true}
                  />
                ))
                }
              </IonGrid>
            ) : (
              <IonList>
                {descriptors.map(descriptor => (
                  <DescriptorItem
                    key={descriptor.id}
                    descriptor={descriptor}
                    onAction={handleAction}
                    selectedAction={descriptorReviews[descriptor.id] || null}
                    isLoading={isPostReviewLoading || areDescriptorsLoading}
                    isDesktop={false}
                  />
                ))
                }
              </IonList>
            )}
            <SubmitButtonContainer>
              <IonIcon
                id="info-icon"
                icon={informationCircle}
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenSubmitHelpPopover(true)}
              />
              <IonAlert
                isOpen={openSubmitHelpPopover}
                onDidDismiss={() => setOpenSubmitHelpPopover(false)}
                message="Submit is enabled once all descriptors have an action selected"
                buttons={['OK']}
              />
              <SubmitButton
                disabled={
                  !allActionsSelected ||
                  isPostReviewLoading ||
                  areDescriptorsLoading
                }
                onClick={handleSubmit}
              >
                Submit
              </SubmitButton>
            </SubmitButtonContainer>
          </>
        ) : (
          <Centered>No descriptors to review</Centered>
        )}
      </IonContent>
    )}

    <IonAlert
      isOpen={!!descriptorsError}
      data-testid="req-error-message"
      cssClass="modal-dark-mode"
      header="Network Error"
      message="There was an error processing your request. Please try again later."
      buttons={['OK']}
    />
  </IonPage>
);
};

export default DescriptorReview;
